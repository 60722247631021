<template>
  <div class="clothing-collection-standard-form">
    <h2 class="fs-h2">
      <span>[</span>
      <span v-text="formattedEventType"></span>
      <span>] </span>
      <span v-text="formData.displayName"></span>
    </h2>
    <div class="content-block">
      <div class="title fs-18px fw-bold">收衣標準</div>
      <ul class="desc fs-14px" v-html="formData.reviewPolicy">
      </ul>
    </div>
    <div class="content-block">
      <div class="title fs-18px fw-bold">注意事項</div>
      <ul class="desc fs-14px" v-html="formData.reviewNotice">
        <li>請確保商品經過清潔並無異味及嚴重破損、不能有仿冒品、不得有影響觀瞻之重大瑕疵 、不能有貼身衣物 (全新品除外)。</li>
        <li>不得臨時撤銷委託銷售，寄售後 90 天內不得取回。</li>
        <li>如實品與照片不相符， 我們將退還物品給您。</li>
        <li>因為庫存空間有限，如商品滯銷時間過久，我們可能會將商品退還給您。退貨運費將由賣家負擔。</li>
      </ul>
    </div>
    <div class="content-block">
      <div class="title fs-18px fw-bold">回饋金機制</div>
      <ul class="desc fs-14px" v-html="formData.reviewReturn">
        <li>以最終銷售金額為準，不限金額，由雙方之間依比例分潤。店家 60%：賣家 40%</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ClothingCollectionStandardForm',
  data() {
    return {
      readingBusyName: 'FOLLOWINGLISTREADING',

      init: false,
    };
  },
  props: {
    formData: {
      type: Object,
      require: true,
    },
  },
  components: {
  },
  computed: {
    formattedEventType() {
      switch (this.formData.type) {
        case 0:
          return '寄售';
        case 1:
          return '捐贈';
        default:
          return '錯誤狀態'
      }
    },
  },
  watch: {
  },
  async mounted() {
  },
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'setAddFriendShow']),
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.clothing-collection-standard-form {
  .content-block {
    padding: 0 .5rem;
    .title {
      margin-bottom: .5rem;
    }
    ul.desc {
      padding-left: 1.25rem;
    }
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.clothing-collection-standard-form {
}
</style>
  