<template>
  <div class="market-place-service">
    <div class="service-list">
      <div class="service-item cursor-pointer" v-for="(item, index) in reviewEventListData" :key="index" @click="serviceClicked(item)">
        <div class="service-image">
          <img v-if="item.accountInfo.profileBanner" :src="item.accountInfo.profileBanner" alt="service image">
        </div>
        <div class="name fs-18px fw-bold" v-text="item.accountInfo.name">
          金魚腦 1.55M 二手商店
        </div>
        <div class="desc fs-14px" v-text="item.accountInfo.desc || '無簡介'">
<!--          <span>寄售｜捐贈</span>-->
<!--          <span>・</span>-->
<!--          <span>男裝｜女裝｜飾品</span>-->
        </div>
      </div>
    </div>

    <transition name="fade">
      <FullScreenModal v-if="showViewService" @back="closeViewServiceModal">
        <ServiceProvider :service-data="currentData" />
      </FullScreenModal>
    </transition>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import FullScreenModal from '@/components/FullScreenModal.vue';
import ServiceProvider from "@/views/share/ServiceProvider.vue";

export default {
  name: 'MarketPlaceService',
  data() {
    return {
      readingBusyName: 'FOLLOWINGLISTREADING',

      init: false,
      reviewEventListData: null,
      currentData: null,
      showViewService: false,
    };
  },
  components: {
    FullScreenModal,
    ServiceProvider,
  },
  computed: {

  },
  watch: {
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    ...mapActions(['showMsgModal', 'appendComponentBusy', 'clearComponentBusy', 'appendErrorMsg', 'setAddFriendShow']),

    async refresh() {
      this.appendComponentBusy(this.readingBusyName);
      try {
        this.reviewEventListData = await this.$store.dispatch('api/getReviewEventListPromise');
      } catch(e) {
        console.error(e);
        this.$emit('error', e);
      } finally {
        this.clearComponentBusy(this.readingBusyName);
      }
    },
    serviceClicked(data) {
      this.showViewService = true;
      this.currentData = data;
    },
    closeViewServiceModal() {
      this.showViewService = false;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/scss/basic";
.market-place-service {
  .service-list {
    padding: .5rem 0;
  }
  .service-item {
    padding: .5rem;
    .name, .desc {
      margin-top: .125rem;
    }
  }
  .service-image {
    position: relative;
    background: $color-image-background;
    width: 100%;
    border-radius: .5rem;
    padding-top: 45%;
    overflow: hidden;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
<style lang="scss">
@import "src/assets/scss/basic";
.market-place-service {
}
</style>
  